<template>
  <div class="employeeDetail positionr" v-loading="loading">
    <!-- 头像更换 -->
    <div class="avatar-uploader positiona"></div>

    <!-- 内容主体 -->
    <div class="bgwhite bort">
      <el-row style="height:100%">
        <!-- tabbar -->
        <!-- <el-col :span="3">
          <div id="navigation">
            <ul>
              <li
                class="menu-item text-cut"
                v-for="(item, i) in tabs"
                :key="item"
                :class="seeThis == i ? 'active' : ''"
              >
                <a href="javascript:void(0)" @click="goAnchor(i)">{{ item }}</a>
              </li>
            </ul>
          </div>
        </el-col> -->
        <!-- tabbar对应内容 -->
        <el-col :span="21">
          <div class="menu-content">
            <!-- 基本信息 -->
            <div class="p1" id="a1">
              <div class="header-search">
                <div class="title">
                  <el-row style="display: flex; flex-wrap: wrap">
                    <el-col :span="12">
                      <span class="line"></span>
                      基本信息
                    </el-col>
                    <el-col :span="12">
                      <div class="flex align-center justify-end h50">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="保存基本信息和扩展属性"
                          placement="bottom"
                        >
                          <el-button
                            type="primary"
                            size="small"
                            @click="submitForm('ruleForm')"
                            >保 存</el-button
                          >
                        </el-tooltip>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="header-input">
                <el-form
                  :model="ruleForm"
                  :rules="rules"
                  ref="ruleForm"
                  label-width="150px"
                  class="ruleForm"
                  size="mini"
                >
                  <el-row style="display: flex; flex-wrap: wrap">
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="代理商名称" prop="agentName">
                        <el-input v-model="ruleForm.agentName"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="代理商编号" prop="agentCode">
                        <el-input
                          v-model="ruleForm.agentCode"
                          disabled
                        ></el-input>
                      </el-form-item>
                    </el-col>

                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="代理商简称" prop="agentSimpleSign">
                        <el-input v-model="ruleForm.agentSimpleSign"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="联系方式" prop="agentContact">
                        <el-input v-model="ruleForm.agentContact"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="代理商负责人" prop="agentHead">
                        <el-input v-model="ruleForm.agentHead"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="授信额度" prop="ad">
                        <el-input v-model="ruleForm.ad"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item
                        label="身份证号"
                        prop="agentIdentificationNumber"
                      >
                        <el-input
                          v-model="ruleForm.agentIdentificationNumber"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="代理商等级" prop="agentGrade">
                        <el-input v-model="ruleForm.agentGrade"></el-input>
                        <!-- <el-select v-model="ruleForm.agentGrade" style="width: 100%;">
                          <el-option label="男" value="1"></el-option>
                          <el-option label="女" value="2"></el-option>
                        </el-select> -->
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="发货杠杆倍数" prop="agentFahuoggbs">
                        <el-input v-model="ruleForm.agentFahuoggbs"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="价格方案" prop="pricePlans">
                        <el-input v-model="ruleForm.pricePlans"></el-input>
                        <!-- <el-select v-model="ruleForm.pricePlans" placeholder="请选择" class="wp100">
                          <el-option label="在职" :value="1"></el-option>
                          <el-option label="离职" :value="2"></el-option>
                          <el-option label="产期休假" :value="3"></el-option>
                          <el-option label="代理" :value="4"></el-option>
                          <el-option label="供应商" :value="5"></el-option>
                          <el-option label="外部" :value="6"></el-option>
                        </el-select> -->
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="24">
                      <el-form-item label="商务条件" prop="businessConditions">
                        <el-input
                          v-model="ruleForm.businessConditions"
                          placeholder="请输入"
                          :rows="3"
                          type="textarea"
                          class="wp100"
                        >
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-col :sm="24" :md="24" :lg="24">
                    <el-form-item label="备注" prop="remark">
                      <el-input
                        v-model="ruleForm.remark"
                        placeholder="请输入"
                        :rows="3"
                        type="textarea"
                        class="wp100"
                      >
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-form>
              </div>
            </div>
            <!-- 子系统账户 -->

            <!-- 对公信息 -->
            <div class="p1" id="a2">
              <div class="header-search">
                <div class="title">
                  <el-row>
                    <el-col :span="24">
                      <div class="flexCenter">
                        <div>
                          <span class="line"></span> <span>对公信息</span>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <el-form
                :model="ruleForm"
                label-width="150px"
                class="ruleForm"
                size="mini"
              >
                <el-row style="display: flex; flex-wrap: wrap">
                  <el-col :sm="24" :md="24" :lg="12">
                    <el-form-item label="企业名称：" prop="businessName">
                      <el-input v-model="ruleForm.businessName"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="24" :md="24" :lg="12">
                    <el-form-item label="税号：" prop="identification">
                      <el-input v-model="ruleForm.identification"></el-input>
                    </el-form-item>
                  </el-col>

                  <el-col :sm="24" :md="24" :lg="12">
                    <el-form-item label="开户行：" prop="bank">
                      <el-input v-model="ruleForm.bank"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="24" :md="24" :lg="12">
                    <el-form-item label="银行账号：" prop="account">
                      <el-input v-model="ruleForm.account"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="24" :md="24" :lg="12">
                    <el-form-item label="单位地址：" prop="addressUnit">
                      <el-input v-model="ruleForm.addressUnit"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="24" :md="24" :lg="12">
                    <el-form-item label="电话：" prop="addressContact">
                      <el-input v-model="ruleForm.addressContact"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
            <!-- 代理区域管理 -->
            <!-- <div class="p1" id="a3">
              <div class="header-search">
                <div class="title">
                  <el-row>
                    <el-col :span="24">
                      <div class="flexCenter">
                        <div>
                          <span class="line"></span> <span> 代理区域管理</span>
                        </div>
                        <el-button
                          plain
                          class="add-column-btn"
                          @click="setselectPostDialog"
                          ><i class="el-icon-plus fw900 f16"></i> 新
                          增</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="header-input">

                <el-table
                  v-loading="loading"
                  :data="postTableData"
                  border
                  fit
                  height="240px"
                  highlight-current-row
                  style="width: 100%"
                >
                  <el-table-column
                    label="省份"
                    align="center"
                    prop="agencyName"
                  ></el-table-column>
                  <el-table-column
                    label="市级"
                    align="center"
                    prop="cityName"
                  ></el-table-column>
                  <el-table-column
                    label="区/县级"
                    align="center"
                    prop="countyName"
                  ></el-table-column>
                  <el-table-column
                    label="操作"
                    align="center"
                    class-name="small-padding"
                    fixed="right"
                    width="100"
                  >
                    <template slot-scope="scope">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="删除"
                        placement="bottom"
                      >
                        <i
                          class="el-icon-close textblue f16 fw700"
                          @click="delPost(scope.row.id, scope.$index)"
                        ></i>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div> -->
            <!-- 与权限的关系 -->
            <!-- <div class="p1" id="a4">
              <div class="header-search">
                <div class="title">
                  <el-row>
                    <el-col :span="24">
                      <div class="flexCenter">
                        <div>
                          <span class="line"></span> <span>代理商权限</span>
                        </div>
                        <el-button plain class="add-column-btn" @click="setAuth"
                          ><i class="el-icon-plus fw900 f16"></i>
                          新增</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="header-input">

                <el-table
                  v-loading="loading"
                  :data="roleTableData"
                  border
                  fit
                  height="240px"
                  highlight-current-row
                  style="width: 100%"
                >
                  <el-table-column label="角色名称" align="center" prop="name">
                  </el-table-column>
                  <el-table-column
                    label="角色描述"
                    align="center"
                    prop="remark"
                  >
                  </el-table-column>
                </el-table>
              </div>
            </div> -->
          </div>
        </el-col>
      </el-row>

      <!--  代理区域管理弹窗  -->
      <el-dialog
        title="代理区域管理"
        :visible.sync="selectPostDialog"
        class="selectorDialog"
      >
        <div class="dlqSelected">
          <el-row>
            <el-col :span="8">
              <div class="dlqBox-title">请选择省份：</div>
              <div class="dlqBox">
                <div class="">
                  <el-radio-group v-model="agencyAreaItem" @change="getCity">
                    <div
                      class="checkboxItem"
                      v-for="item in agencyArea"
                      :key="item.id"
                    >
                      <el-radio :label="item">{{ item.name }}</el-radio>
                    </div>
                  </el-radio-group>
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="dlqBox-title">请选择城市：</div>
              <div class="dlqBox">
                <div class="">
                  <el-radio-group v-model="cityItem" @change="getCounty">
                    <div
                      class="checkboxItem"
                      v-for="(item, index) in cityList"
                      :key="item.postCode"
                    >
                      <el-radio :label="item">{{ item.name }}</el-radio>
                    </div>
                  </el-radio-group>
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="dlqBox-title">请选择区：</div>
              <div class="dlqBox">
                <div class="">
                  <el-radio-group
                    v-model="countyItem"
                    @change="handleCheckedItemsChange"
                  >
                    <div
                      class="checkboxItem"
                      v-for="item in countyList"
                      :key="item.postCode"
                    >
                      <el-radio :label="item">{{ item.name }}</el-radio>
                    </div>
                  </el-radio-group>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="confirmPost">确 认</el-button>
          <el-button @click="selectPostDialog = false">取 消</el-button>
        </div>
      </el-dialog>

      <!-- 选择权限弹窗 -->
      <el-dialog
        title="选择权限"
        :visible.sync="selectAuthorityDialog"
        class="selectorDialog"
      >
        <!-- <div class="search-condition box">
          <el-row type="flex" justify="end">
            <el-col :span="16">
              <el-input placeholder="描述/权限组" class="mr10">
                <el-button slot="append" icon="el-icon-search"></el-button>
              </el-input>
            </el-col>
          </el-row>
        </div> -->

        <div class="org-selected">
          <el-row>
            <el-col :span="24">
              <el-table
                ref="multipleTable"
                :data="selectAuthorityData"
                tooltip-effect="dark"
                style="width: 100%; margin-top: 0"
                @selection-change="handleSelectionChange"
                border
                fit
                :height="354"
                highlight-current-row
              >
                <el-table-column type="selection" width="55" align="center">
                </el-table-column>
                <el-table-column type="index" label="序号" align="center">
                </el-table-column>
                <el-table-column prop="name" label="角色名称" align="center">
                </el-table-column>
                <el-table-column prop="remark" label="角色说明" align="center">
                </el-table-column>
              </el-table>
              <!-- 分页 -->
              <!-- <el-pagination
                  @size-change="handleSizeChangeAuthority"
                  @current-change="handleCurrentChangeAuthority"
                  :current-page="queryInfoAuthority.currPage"
                  :page-size="queryInfoAuthority.pageSize"
                  layout="total, prev, pager, next"
                  :total="totleAuthority"
                  class="ml10"
                  style="margin-bottom: 15px"
                ></el-pagination> -->
            </el-col>
          </el-row>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="confirmAuthority">确 认</el-button>
          <el-button @click="selectAuthorityDialog = false">取 消</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    // 自定义手机号规则
    var checkMobile = (rule, value, callback) => {
      const regMobile = /^1[34578]\d{9}$/;
      if (regMobile.test(value)) {
        return callback();
      }
      // 返回一个错误提示
      callback(new Error("请输入合法的手机号码"));
    };
    return {
      parentCode: "",
      talkType: "add",
      talkTotal: 0,
      talkQueryInfo: {
        currPage: 1,
        pageSize: 20,
        condition: {
          userCode: this.$route.query.userCode,
        },
      },

      talkTable: [],
      dialogVisibleInner: false,
      dialogImageUrl: "",

      fileList: [],
      talkForm: {
        id: "",
        userCode: "",
        reward: "",
        talkor: "",
        content: "",
        talkDt: "",
      },
      seeThis: 0,
      tabs: ["基本信息", "对公信息",
      // "代理区域管理", "代理商权限"
      ], //  // "扩展属性",
      // "汇报关系",
      ruleForm: {
        id: "",
        name: "",
        phone: "",
        ad: "",
        sex: "1",
        status: 1,
        employeeType: 1,
        escapContacts: "",
        maritalStatus: "",
        restType: 1,
        signNotice: 1,
      },
      rules: {
        agentName: [
          { required: true, message: "代理商名称不能为空", trigger: "blur" },
        ],
        agentSimpleSign: [
          { required: true, message: "代理商简称不能为空", trigger: "blur" },
        ],
        agentContact: [
          { required: true, message: "联系方式不能为空", trigger: "blur" },
        ],
        agentHead: [
          { required: true, message: "代理商负责人不能为空", trigger: "blur" },
        ],
        agentGrade: [
          { required: true, message: "代理商等级不能为空", trigger: "blur" },
        ],
        agentFahuoggbs: [
          { required: true, message: "发货杠杆倍数不能为空", trigger: "blur" },
        ],
        pricePlans: [
          { required: true, message: "价格方案不能为空", trigger: "blur" },
        ],
        businessConditions: [
          { required: true, message: "商务条件不能为空", trigger: "blur" },
        ],
      },
      tableData: [],
      id: "",
      userCode: "",
      disabledFalg: false,
      loading: true,
      imageUrl: "",
      userName: "",
      orgTableData: [], //与组织的关系表格数据
      treeData: [], // 组织树形结构数据
      defaultProps: {
        children: "childrens",
        label: "deptName",
      },
      defaultKyes: [], //选中节点
      defaultId: "", //当前节点
      multipleSelection: [], // 组织选中列表
      postTableData: [], //与岗位的关系表格数据
      selectPostDialog: false, // 选择岗位弹窗
      selectPostData: [], // 岗位信息表格数据
      multipleSelection2: [], // 岗位选中列表
      checkAll: false, //全选状态
      checkedItems: [], // 默认选中
      isIndeterminate: true, //表示 checkbox 的不确定状态，一般用于实现全选的效果
      queryInfoPost: {
        // 当前页数
        page: 1,
        // 每页显示多少数据
        size: 20,
      },
      totlePost: "",

      roleTableData: [], // 与权限的关系表格数据
      selectAuthorityDialog: false, //选择权限弹窗
      selectAuthorityData: [], // 选择权限列表-弹窗
      multipleSelection3: [], // 权限选中列表

      queryInfoAuthority: {
        // 当前页数
        currPage: 1,
        // 每页显示多少数据
        pageSize: 100,
      },
      totleAuthority: "",

      agencyArea: [],
      agencyAreaItem: {},
      cityItem: {},
      cityList: [],
      countyItem: {},
      countyList: [],
    };
  },
  created() {
    this.id = this.$route.query.id;
    // if (this.id == undefined || this.id == "") {
    //   this.disabledFalg = true;
    // } else {
    //   this.userCode = this.$route.query.userCode;
    //   this.disabledFalg = false;
    // }
    if (this.$route.query.id) {
      this.$http
        .post("houseAgent/queryOne", { id: this.$route.query.id })
        .then((res) => {
          if (res.data.code === 200) {
            this.ruleForm = res.data.data;
          //  let agentArea = this.ruleForm.agentArea.split(',')
          //  let agentAreaCode = this.ruleForm.agentAreaCode.split(',')
          //  console.log(arr,615);
          }
          console.log(res, 610);
        });
    }

    this.userName = JSON.parse(localStorage.getItem("userInfo")).userInfo.name;
    this.getAddress();
  },
  mounted() {
    var that = this;
    // this.getTalkTable();
    this.$el.querySelector(".menu-content").onscroll = () => {
      that.handleScroll();
    }; // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    that.loading = false;
  },

  methods: {
    // 获取省份等
    async getAddress() {
      let res = await this.$http.post("AgentPlatform/region", {});
      this.agencyArea = res.data.data;
      console.log(this.agencyArea, "kkkkkkkk");
    },
    // 获取市
    getCity() {
      this.cityList = this.agencyAreaItem.children;
      this.countyList = [];
    },
    // 获取县
    getCounty() {
      console.log(this.cityItem, 635);
      if (this.cityItem.children.length > 0) {
        this.countyList = this.cityItem.children;
      } else {
        let multipleSelect = {
          agencyName: this.agencyAreaItem.name,
          agencyCode: this.agencyAreaItem.id,
          cityName: this.cityItem.name,
          cityCode: this.cityItem.id,
          countyName: this.countyItem.name ? this.countyItem.name : "",
          countyCode: this.countyItem.id ? this.countyItem.id : "",
        };
        this.multipleSelection2 = [multipleSelect];
      }
    },
    // 代理区域管理新增
    setselectPostDialog() {
      this.selectPostDialog = true;
      this.getPostInfoData();
    },
    // 左边对应滑动动画
    goAnchor(index) {
      this.$nextTick(() => {
        document.querySelector("#a" + (index + 1)).scrollIntoView({
          behavior: "smooth", // 平滑过渡
          block: "start",
        });
      });
    },
    handleScroll() {
      // console.log(document.querySelector(".menu-content").scrollTop)
    },

    // 获取代理商区域管理数据
    async getPostInfoData() {
      let res = await this.$http.post("post/queryList", {
        groupCode: JSON.parse(localStorage.getItem("userInfo")).userInfo
          .engineeringGroup.groupCode,
        deptCode: this.parentCode,
      });
      if (res.data.code == 200) {
        this.selectPostData = res.data.data;
        // this.totlePost = res.data.data.count;
      } else {
        this.$message({
          message: res.data.message,
          duration: 1500,
          type: "error",
        });
      }
    },
    // 代理商权限新增
    setAuth() {
      this.selectAuthorityDialog = true;
      // 清除原有数据
      this.agencyAreaItem = {};
      this.cityItem = {};
      this.countyItem = {};
      this.getRoleInfo();
    },
    // 代理区域管理删除事件
    async delPost(id, index) {
      var that = this;
      const confirmResult = await that
        .$confirm("确定删除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return that.$notify.info({
          title: "提示",
          message: "已取消删除",
          showClose: true,
        });
      } else {
        this.postTableData.splice(index, 1);
      }
    },
    // 代理商区域管理选择-弹窗
    handleCheckedItemsChange(value) {
      let multipleSelect = {
        agencyName: this.agencyAreaItem.name,
        agencyCode: this.agencyAreaItem.id,
        cityName: this.cityItem.name,
        cityCode: this.cityItem.id,
        countyName: this.countyItem.name ? this.countyItem.name : "",
        countyCode: this.countyItem.id ? this.countyItem.id : "",
      };
      console.log(multipleSelect, 715);

      this.multipleSelection2 = [multipleSelect];
    },
    // 代理区域管理确认-弹窗
    confirmPost() {
      this.postTableData.push(...this.multipleSelection2);
      console.log(this.multipleSelection2, 722);
      this.selectPostDialog = false;
    },
    // 获取权限信息数据
    getRoleInfo() {
      var that = this;
      that.$http
        .post("AgentPlatform/PermissionsLsit", {})
        .then(function (response) {
          if (response.data.code == 200) {
            that.selectAuthorityData = response.data.data;
          } else {
            that.$message({
              message: response.data.message,
              duration: 1500,
              type: "error",
            });
          }
        });
    },
    // 与权限的关系删除事件
    async delRole(id) {
      var that = this;
      const confirmResult = await that
        .$confirm("确定删除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return that.$notify.info({
          title: "提示",
          message: "已取消删除",
          showClose: true,
        });
      } else {
        this.selectAuthorityData.splice(index, 1);
      }
    },
    //权限选择器选择事件-弹窗
    handleSelectionChange(val) {
      this.multipleSelection3 = val;
      // console.log(this.multipleSelection3);
    },
    // 选择权限确认-弹窗
    async confirmAuthority() {
      this.roleTableData.push(...this.multipleSelection3);
      this.selectAuthorityDialog = false;
    },
    // 选择权限-监听 pagesize改变的事件
    handleSizeChangeAuthority(newSize) {
      // console.log(newSize)
      this.queryInfoAuthority.pageSize = newSize;
      this.getRoleInfo();
    },
    // 选择权限-监听 页码值 改变事件
    handleCurrentChangeAuthority(newSize) {
      // console.log(newSize)
      this.queryInfoAuthority.currPage = newSize;
      this.getRoleInfo();
    },
    // 表单提交
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          console.log(valid);
          let data = JSON.parse(JSON.stringify(this.ruleForm));
          // if (this.postTableData.length == 0) {
          //   this.$message.info("请选择代理区域");
          //   return;
          // }
          // if (this.roleTableData.length == 0) {
          //   this.$message.info("请选择代理商权限");
          //   return;
          // }
           data.createName = JSON.parse(localStorage.getItem("userInfo")).userInfo.name;
          // 代理商区域code
          data.agentAreaCode = this.postTableData
            .map((item) => {
              if (item.countyCode) {
                return item.countyCode;
              } else if (item.cityCode) {
                return item.cityCode;
              } else if (item.agencyCode) {
                return item.item.agencyCode;
              }
            })
            .join();
          // 代理商区域名称
          data.agentArea = this.postTableData
            .map((item) => {
              console.log(item);
              if (item.agencyName) {
                return item.agencyName;
              } else if (item.cityName) {
                return item.cityName;
              } else if (item.countyName) {
                return item.item.countyName;
              }
            })
            .join();
          // 权限code
          data.agentQxCode = this.roleTableData
            .map((item) => {
              return item.roleCode;
            })
            // .join();
          // 权限备注
          data.agentQx = this.roleTableData
            .map((item) => {
              return item.remark;
            })
            .join();
          console.log(this.roleTableData, 674);
  
          let res = await this.$http.post("/houseAgent/update", data);
          if (res.data.code == 200) {
            this.$notify.success({
              title: "提示",
              message: "修改成功",
              showClose: true,
            });
            this.$router.push({
              path: "/agentManagement/agentManagement",
            });
          } else {
            this.$message.error(res.data.message);
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.h50 {
  height: 50px;
}

.el-button.add-column-btn {
  font-size: 13px;
  background-color: transparent;
  color: #008eff;
  margin-top: -1px;
  position: relative;
  z-index: 100;
}

.employeeDetail {
  height: 92vh;
  .bort {
    height: 100%;
  }
  .el-breadcrumb__inner {
    color: #2f91df;
  }

  .avatar-uploader {
    top: -16px;
    right: 20px;
    z-index: 10;
  }

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .el-row {
    #navigation {
      background-color: #f6f7fb;
      font-size: 14px;
      height: calc(100vh - 110px);
      margin-top: -14px;

      li {
        height: 50px;
        line-height: 50px;

        a {
          display: block;
          color: #324059;
          padding-left: 20px;
          background-color: transparent;
        }
      }

      li.active,
      li:hover {
        background-color: #fff;
      }
    }

    .menu-content {
      height: calc(100vh - 110px);
      overflow-y: auto;
      padding-left: 10px;
      padding-right: 5px;

      .p1 {
        margin-bottom: 20px;
      }

      .header-search {
        .title {
          // height: 50px;
          font-weight: 700;
          line-height: 49px;
          font-size: 15px;
          color: #2f405b;
          padding-left: 10px;
          border-bottom: 1px solid #e3eaf0;
          margin-bottom: 10px;

          .line {
            display: inline-block;
            width: 3px;
            height: 15px;
            background-color: #2f91df;
            margin-right: 5px;
            border-radius: 2px;
            margin-bottom: -2px;
          }

          .el-input {
            width: 247px;
          }

          .el-input .el-input__inner {
            width: 217px;
          }
        }
      }

      .header-input {
        font-size: 13px;
      }

      .model-mask {
        .el-form {
          .el-form-item {
            margin-bottom: 0;
          }
        }

        .el-table {
          margin-top: 10px;
        }
      }
    }
  }

  #selectorOrgDialog .el-dialog {
    width: 600px !important;
  }

  #selectorOrgDialog .selectedUl {
    height: 350px;
    max-height: 350px;
  }

  #selectorOrgDialog .org-selected .selectedUl-box {
    height: 320px !important;
    overflow: auto;
  }

  .dlqSelected {
    // height: 200px;
    overflow: auto;

    .dlqBox {
      height: 500px;
      overflow: auto;
      border-right: 1px solid #e3eaf0;
      padding-left: 25px;
      box-sizing: border-box;
    }
    .dlqBox-title {
      color: #22abee;
      margin: 0 0 10px 10px;
    }
  }
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.upload-col {
  .el-upload-list__item {
    transition: none !important;
  }

  .el-upload--picture-card {
    height: 100px;
    width: 100px;
    line-height: 100px;
  }
}

.el-upload-list--picture-card .el-upload-list__item {
  height: 100px;
  width: 100px;
}

/* 自定义选中状态下的图标颜色 */
::v-deep .el-radio__input.is-checked .el-radio__inner::after {
  //变成对号
  content: "\2713";
  /* Unicode编码的勾号 */
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  color: white;
  background-color: #007bff;
  /* 勾号的颜色 */
  font-size: 10px;
  /* 勾号的大小 */
  line-height: 12px;
  border-radius: 0;
}
::v-deep .el-radio__inner {
  border-radius: 0;
}

::v-deep .el-dialog__body {
  padding: 0 20px;
}
</style>
